body {
  background: rgb(238,174,202);
  background: linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
  font-size:16px;
}
.menu-container {
  display:flex;
  justify-content: center;
  padding-top:20px;
}
.menu-inner{
  width:340px;
  display:flex;
  flex-wrap: wrap;
}
.menu-text{
  width:15%;
  display:flex;
  flex-direction: column;
  justify-content: center;
}
.menu-text span{
  text-align: center;
}
.menu-select{
  width:84%;
}
.main-container{
  width: 100vw;
  min-width:301px;
  margin-top:20px;
  display:flex;
  justify-content:center;
}
.main-inner{
  height:340px;
  width:35%;
  background:rgba(255,255,255,0.5);
  border-radius:20px;
  border:1px solid #fff;
  overflow:hidden;
  position:absolute;
}
.main-column{
  display:flex;
  flex-direction: row;
}
.main-column:first-child{
  border-bottom: 1px solid #eee;
}
.main-row{
  display:flex;
  width:100%;
}
.main-item{
  padding-top:10px;
  padding-bottom:10px;
  width:100%;
  color:#555;
}
.f .main-item:first-child{
  background-color: rgba(0,0,0,0.1);
}
.main-item div:first-child{
  text-align: center;
}
.main-img{
  display:flex;
  justify-content: center;
}
.main-temps{
  text-align: center;
  justify-content: center;
  display: flex;
}
@media screen and (max-width: 1280px) {
  .main-inner{
    width:60%;
  }
}
@media screen and (max-width: 768px) {
  body{
    margin-bottom:800px;
  }
  .main-column:first-child{
    border:none;
  }
  .main-row{
    border-bottom: 1px solid #eee;
  }
  .main-inner{
    width:90%;
    height:683px;
  }
  .main-column{
    flex-direction: column;
  }
  .main-item:first-child{
    border-right:1px solid #eee;
  }
}





.title-word {
  animation: color-animation 4s linear infinite;
}

.title-word-1 {
  --color-1: #2f37e0;
  --color-2: #50005e;
  --color-3: #7d00c5;
}

.title-word-2 {
  --color-1: #009666;
  --color-2: #005269;
  --color-3: #17494D;
}

.title-word-3 {
  --color-1: #005269;
  --color-2: #7d00c5;
  --color-3: #005269;
}

.title-word-4 {
  --color-1: #50005e;
  --color-2: #2f37e0;
  --color-3: #7d00c5;
}

@keyframes color-animation {
  0%    {color: var(--color-1)}
  32%   {color: var(--color-1)}
  33%   {color: var(--color-2)}
  65%   {color: var(--color-2)}
  66%   {color: var(--color-3)}
  99%   {color: var(--color-3)}
  100%  {color: var(--color-1)}
}
.copyright{
  position:fixed;
  bottom:10px;
  right:10px;
  color:#fff;
  font-weight: bold;
  text-decoration: none;
}
.copyright:hover{
  border-bottom: 2px solid #eee;
}